@tailwind base;
@tailwind components;
@tailwind utilities;

input[role='searchbox']:focus {
    outline: none;
    @apply outline-none;
}

.ng-touched.ng-invalid:not(form):not(div) {
    @apply border border-2 border-brand-danger;
}

.cb-user-multiselect {
    @apply block focus:outline-none;
}

@layer utilities {
    .striped>div:nth-child(even) {
        @apply bg-bright-gray;
    }
}

@keyframes p-progress-spinner-color {

    100%,
    0% {
        stroke: #00ab9e;
    }
}